<template>
  <section>
    <div class="tab" v-for="(tab,index) in tabs.list"
         :key="`tab${index}`"
         :class="{active: index === tabs.current}"
          @click="changeCurrentTab(index)">
      {{ tab.title }}
    </div>
  </section>
</template>

<script>
export default {
  name: 'TabsList',
  props: {
    tabs: {
      type: Object,
      required: true
    }
  },
  methods: {
    changeCurrentTab (index) {
      this.$emit('changeCurrentTab', index)
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    display: flex;
    border-bottom: .06rem solid $neutral-secondary;

    > .tab {
      margin-right: 1.25rem;
      cursor: pointer;
      font-size: .812rem;
      line-height: 1.25rem;
      color: #003E78;
      opacity: 0.6;
      padding-bottom: 1.375rem;
    }

    > .active {
      color: $primary-color;
      font-weight: 600;
      opacity: 1;
      border-bottom: .125rem solid $primary-color;
    }
  }
  @media screen and (max-width: 420px) {
    section {
      overflow-x: scroll;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
        > .tab {
          flex: 0 0 auto;
        }
    }
  }
</style>
