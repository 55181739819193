<template>
  <section>
    <data-field v-for="(dataField,index) in dataFields" :data-field="dataField" :key="`dataField${index}`"/>

    <div class="buttons__container">
      <router-link to="/" class="btn-white">
        Отмена
      </router-link>

      <button class="btn-blue" @click="changeCurrentTab(1)">
        Далее
      </button>
    </div>
  </section>
</template>

<script>
import DataField from './DataField'

export default {
  name: 'FirstStep',
  data () {
    return {
      dataFields: []
    }
  },

  mounted () {
    if (this.$store.getters.getIndividualProject.lenth !== 0) {
      this.dataArr()
    }
  },
  watch: {
    '$store.getters.getIndividualProject' () {
      this.dataArr()
    }
  },
  methods: {
    changeCurrentTab (index) {
      this.$emit('changeCurrentTab', index)
    },
    dataArr () {
      const data = this.$store.getters.getIndividualProject.personal
      const fullData = this.$store.getters.getIndividualProject
      if (data) {
        this.dataFields = [
          {
            label: 'Фамилия',
            content: data.last_name
          },
          {
            label: 'Имя',
            content: data.first_name
          },
          {
            label: 'Отчество',
            content: data.patronymic
          },
          {
            label: 'Дата рождения',
            content: new Date(data.birthday_date).toLocaleString('ru-ru', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric'
            })
          },
          {
            label: 'Серия и номер паспорта',
            content: data.passport_number
          },
          {
            label: 'Место рождения',
            content: data.birthday_place
          },
          {
            label: 'E-mail',
            content: data.email
          },
          {
            label: 'Номер телефона',
            content: data.phone
          },
          {
            label: 'ФО реализации проекта',
            content: fullData.personal_model && fullData.personal_model.federal_district ? fullData.personal_model.federal_district.title : ''
          },
          {
            label: 'Регион проживания',
            content: fullData.personal_model && fullData.personal_model.region ? fullData.personal_model.region.name : ''
          },
          {
            label: 'Город или населенный пункт',
            content: data.city
          },
          {
            label: 'Направление Вашей деятельности',
            content: fullData.nomination_model ? fullData.nomination_model.title : ''
          },
          {
            label: 'Ваша роль в проектной команде',
            content: data.role
          },
          {
            label: 'Откуда Вы узнали о конкурсе?',
            content: data.source
          },
          {
            label: 'Подавали ли Вы заявку на Премию «Я — гражданин» в 2014, 2015 и 2016 годах и на конкурс «Мой проект – моей стране!» в 2017 году? Если да, напишите год и номинацию.',
            content: data.previous_application
          }
        ]
      }
    }
  },
  components: {
    DataField
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1.5rem;
    }

    > .buttons__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.375rem;
      margin-top: 2.5rem;
      border-top: .06rem solid $neutral-secondary;
      margin-bottom: 0;

      > .btn-white, > .btn-blue {
        width: 10rem;
        text-align: center;
      }
    }
  }
</style>
