<template>
  <div class="white-container">
    <tabs-list :tabs="tabs" @changeCurrentTab="changeCurrentTab($event)"/>

    <component :is="tabs.list[tabs.current].component" @changeCurrentTab="changeCurrentTab($event)"/>
  </div>
</template>

<script>
import TabsList from './TabsList'
import FirstStep from './FirstStep'
import SecondStep from './SecondStep'
import ThirdStep from './ThirdStep'
import FourthStep from './FourthStep'

export default {
  name: 'ProjectMapData',
  data () {
    return {
      tabs: {
        current: 0,
        list: [
          {
            title: 'Заявка',
            component: 'FirstStep'
          },
          {
            title: 'Об организации',
            component: 'SecondStep'
          },
          {
            title: 'О деятельности',
            component: 'ThirdStep'
          },
          {
            title: 'О проекте',
            component: 'FourthStep'
          }
        ]
      }
    }
  },
  methods: {
    changeCurrentTab (index) {
      this.tabs.current = index
    }
  },
  components: {
    FirstStep,
    SecondStep,
    ThirdStep,
    FourthStep,
    TabsList
  }
}
</script>

<style scoped lang="scss">
  .white-container {
    width: 61.5rem;
    margin: 0 0 auto -2.5rem;
  }
  @media screen and (max-width: 768px){
    .white-container {
      width: 100%;
      margin-left: 0;
      padding: 1.7rem 2rem !important;
    }
  }
</style>
