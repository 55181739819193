<template>
  <article>
    <p class="label">
      {{ dataField.label }}
    </p>

    <div class="dashed__line"></div>

    <p class="data" v-html="dataField.content">
    </p>
  </article>
</template>

<script>
export default {
  name: 'DataField',
  props: {
    dataField: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  article {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > .label {
      font-size: .75rem;
      line-height: 1.125rem;
      color: #5E686C;
      max-width: 25.562rem;
    }

    > .dashed__line {
      flex-grow: 1;
      height: .125rem;
      border: .06rem dashed $neutral-secondary;
      margin-bottom: .250rem;
    }

    ::v-deep > .data {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      max-width: 25.562rem;
      text-align: right;

      a {
        color: $primary-color;
      }
    }
  }
</style>
