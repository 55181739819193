<template>
  <section>
    <data-field v-for="(dataField,index) in dataFields.slice(0,4)" :data-field="dataField" :key="`dataField${index}`"/>

    <h3>
      Ссылка на страницы вашего проекта:
    </h3>

    <data-field v-for="(dataField,index) in dataFields.slice(4,9)" :data-field="dataField" :key="`1dataField${index}`"/>

    <h3>
      Приложения
    </h3>

    <div class="gallery__wrapper">
      <a :href="photo" v-for="(photo, index) in $store.getters.getIndividualProject.description.photo" :key="`0photo${index}`" target="_blank">
        <img src="/assets/icons/document_icon.svg">
      </a>
    </div>

    <!--<data-field v-for="(dataField,index) in dataFields.slice(7,9)" :data-field="dataField" :key="`2dataField${index}`"/>-->

    <h3>
      Фотогалерея
    </h3>

    <div class="gallery__wrapper">
      <a :href="photo" v-for="(photo, index) in $store.getters.getIndividualProject.description.gallery" :key="`1photo${index}`" target="_blank">
        <img :src="photo" alt="">
      </a>
    </div>

    <data-field v-for="(dataField,index) in dataFields.slice(9)" :data-field="dataField" :key="`3dataField${index}`"/>

    <div class="buttons__container">
      <button class="btn-white" @click="changeCurrentTab(2)">
        Назад
      </button>

      <button class="btn-red" @click="setReview(false)" v-if="getProjectUserRole === 'ORGANIZER' && getIndividualProject.status_code === 'UNDER_CONSIDERATION'">
        Отклонить
      </button>

      <button class="btn-green" @click="setReview(true)" v-if="getProjectUserRole === 'ORGANIZER' && getIndividualProject.status_code === 'UNDER_CONSIDERATION'">
        Одобрить
      </button>
    </div>
  </section>
</template>

<script>
import DataField from './DataField'

export default {
  name: 'FourthStep',

  data () {
    return {
      dataFields: []
    }
  },

  mounted () {
    if (this.$store.getters.getIndividualProject.lenth !== 0) {
      this.dataArr()
    }
  },

  watch: {
    '$store.getters.getIndividualProject' () {
      this.dataArr()
    }
  },

  methods: {
    changeCurrentTab (index) {
      this.$emit('changeCurrentTab', index)
    },

    dataArr () {
      console.log(this.$store.getters.getIndividualProject.description)
      const data = this.$store.getters.getIndividualProject.description
      const releaseStageText = {
        PLANNING: 'планирование проекта',
        REALIZATION: 'реализация проекта',
        REALIZED: 'проект реализован'
      }
      this.dataFields = [
        {
          label: 'Название проекта',
          content: data.name
        },
        {
          label: 'Кратко опишите проект: цели, задачи, формат реализации и географию проекта',
          content: data.description
        },
        {
          label: 'Опишите, на кого направлен Ваш проект?',
          content: data.direction
        },
        {
          label: 'Ссылка на сайт проекта',
          content: data.site
        },
        {
          label: 'в ВКонтакте',
          content: data.profile_instagram
        },
        {
          label: 'в Facebook',
          content: data.profile_facebook
        },
        {
          label: 'в других социальных сетях (Одноклассники, Instagram, Twitter)',
          content: data.profile_other
        },
        {
          label: 'На какой стадии реализации находится Ваш проект?',
          content: releaseStageText[data.release_stage]
        },
        {
          label: 'Сроки реализации проекта?',
          content: data.release_term
        },
        /*
        {
          label: 'Приложение №1',
          content: '<a href="/">Скачать (PDF) 217 Кб</a>'
        },
        {
          label: 'Приложение №2',
          content: '<a href="/">Скачать (PDF) 217 Кб</a>'
        },
        */
        {
          label: 'Укажите, на решение каких социальных проблем направлена деятельность Вашего проекта',
          content: data.solves
        },
        {
          label: 'Чем деятельность Вашего проекта отличается от деятельности подобных проектов?',
          content: data.activity_differences
        },
        {
          label: 'Опишите стратегию развития и план реализации проекта?',
          content: data.strategy
        },
        {
          label: 'Опишите каналы коммуникации с Вашей аудиторией (где и как Вы общаетесь с Вашими целевыми группами)',
          content: data.communication_channels
        },
        {
          label: 'Перечислите наиболее значимые на Ваш взгляд результаты, которые Вы достигли в процессе реализации проектов, и за какой период времени',
          content: data.results
        },
        {
          label: 'Укажите сколько человек работает над реализацией проекта? Их ФИО, должность и функционал',
          content: data.staff
        },
        {
          label: 'Привлекаете ли Вы к работе волонтеров? Если да, то какое количество и на какие задачи',
          content: data.volunteers
        },
        {
          label: 'Источники финансирования. На какие средства существует организация/проект?',
          content: data.financing
        },
        {
          label: 'Подавали ли Вы ваш проект на премию и конкурсы? Если да, то укажите на какие именно и конечный результат',
          content: data.competitions_appeals
        },
        {
          label: 'Кто может дать рекомендации вашему проекту? Укажите ФИО, должность рекомендателей и их контакты',
          content: data.recommendations
        },
        {
          label: 'Ваши комментарии (что еще вы считаете важным сообщить?)',
          content: data.comment
        }
      ]
    },

    async setReview (status) {
      const str = status ? 'review' : 'reject'

      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals/${this.$route.params.id}/${str}`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => {
          if (res.status === 200) {
            this.$router.push('/projects-list')
            this.$store.commit('setMessage', {
              type: 'success',
              text: 'Статус успешно изменен'
            })
          } else {
            this.$store.commit('setMessage', {
              type: 'error',
              text: 'Ошибка изменения статуса'
            })
          }
        })
        .catch(error => console.log('error', error))
    }
  },

  computed: {
    getProjectUserRole () {
      return this.$store.getters.getProjectUserRole
    },
    getIndividualProject () {
      return this.$store.getters.getIndividualProject
    }
  },

  components: {
    DataField
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1.5rem;
    }

    > .buttons__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.375rem;
      margin-top: 2.5rem;
      border-top: .06rem solid $neutral-secondary;
      margin-bottom: 0;

      > .btn-white, > .btn-blue, > .btn-red, > .btn-green {
        width: 10rem;
        text-align: center;
      }

      > .btn-red {
        margin-left: auto;
        margin-right: 1.375rem;
      }
    }

    > h3 {
      font-weight: 600;
      font-size: .875rem;
      line-height: 1.375rem;
      color: #5E686C;
    }

    > .gallery__wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      img {
        width: 4.562rem;
        height: 3.312rem;
        object-fit: cover;
        object-position: center;
        border-radius: .5rem;
        margin-right: .5rem;
        margin-bottom: .5rem;

        &:hover {
          filter: brightness(.5);
        }
      }
    }
  }
</style>
