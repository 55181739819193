<template>
  <section>
    <p>
      {{ question.order }}. {{ question.title }}
    </p>

    <div class="answers">
      <div v-for="answer in 10" :key="`${index}reviewQuestion${answer}`"
           @click="submitReviewQuestion(answer)"
           :class="{'active': question.answer == answer, 'selected': question.answer > answer}">
        {{ answer }}
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'ReviewQuestion',

  props: {
    question: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: true
    }
  },

  methods: {
    submitReviewQuestion (answerIndex) {
      if (this.isEditing) {
        this.$emit('submitReviewQuestion', {
          review: this.index,
          answer: answerIndex
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    p {
      font-size: .875rem;
      line-height: 1.375rem;
      color: $mainFontColor;
      margin-bottom: .75rem;
    }

    .answers {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.875rem;

      > div {
        cursor: pointer;
        width: 2.375rem;
        height: 2.875rem;
        background: $neutral-placeholder;
        border: .06rem solid white;
        box-sizing: border-box;
        border-radius: 0.187rem;

        font-size: .875rem;
        line-height: 1.375rem;

        display: flex;
        align-items: center;
        justify-content: center;

        color: $primary-color;
      }

      > .active {
        background-color: $primary-color;
        color: white;
      }

      > .selected {
        background-color: #DDEDFE;
      }
    }
  }
</style>
