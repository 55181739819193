<template>
  <section>
    <data-field v-for="(dataField,index) in dataFields.slice(0,6)" :data-field="dataField" :key="`0dataField${index}`"/>

    <article class="photo">
      <p class="label">
        Фото
      </p>
      <div class="list">
        <a :href="photo" v-for="(photo, index) in $store.getters.getIndividualProject.activity.photo" :key="`photo${index}`" target="_blank">
          <img :src="photo" alt="">
        </a>
      </div>
    </article>

    <article class="photo">
      <p class="label">
        Поддержка
      </p>
      <div v-for="(support, index) in JSON.parse($store.getters.getIndividualProject.activity.support)" :key="`support${index}`" v-show="support.value">
        <p>{{ support.label }}</p>
        <p>Название организации: {{ support.nameValue }}</p>
        <p>Вид поддержки: {{ support.kindValue }}</p>
      </div>
    </article>

    <data-field v-for="(dataField,index) in dataFields.slice(6,8)" :data-field="dataField" :key="`1dataField${index}`"/>

    <div class="buttons__container">
      <button class="btn-white" @click="changeCurrentTab(1)">
        Отмена
      </button>

      <button class="btn-blue" @click="changeCurrentTab(3)">
        Далее
      </button>
    </div>
  </section>
</template>

<script>
import DataField from './DataField'

export default {
  name: 'ThirdStep',
  data () {
    return {
      dataFields: []
    }
  },

  mounted () {
    if (this.$store.getters.getIndividualProject.lenth !== 0) {
      this.dataArr()
    }
  },
  watch: {
    '$store.getters.getIndividualProject' () {
      this.dataArr()
    }
  },
  methods: {
    changeCurrentTab (index) {
      this.$emit('changeCurrentTab', index)
    },
    dataArr () {
      const data = this.$store.getters.getIndividualProject.activity
      this.dataFields = [
        {
          label: 'О себе',
          content: data.about
        },
        {
          label: 'Профиль в ВКонтакте',
          content: data.profile_instagram
        },
        {
          label: 'Профиль в Facebook',
          content: data.profile_facebook
        },
        {
          label: 'Другая соц. сеть',
          content: data.profile_other
        },
        {
          label: 'Является ли деятельность социально-значимой',
          content: data.comment_activity_significant
        },
        {
          label: 'Состоит ли в общественных организациях',
          content: data.comment_participation_organizations
        },
        {
          label: 'Публикации в СМИ',
          content: data.mass_media_publications
        },
        {
          label: 'Награды',
          content: data.awards
        },
        {
          label: 'Планирование совместных активностей с организациями / некоммерческими проектами, бизнесом и государством в ближайший год',
          content: data.joint_activities
        }
      ]
    }
  },
  components: {
    DataField
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1.5rem;
    }

    > .buttons__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.375rem;
      margin-top: 2.5rem;
      border-top: .06rem solid $neutral-secondary;
      margin-bottom: 0;

      > .btn-white, > .btn-blue {
        width: 10rem;
        text-align: center;
      }
    }

    .photo {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      > div {
        margin-bottom: .5rem;

        > p {
          font-weight: 600;
          font-size: .875rem;
          line-height: 1.375rem;
          color: $mainFontColor;
          max-width: 25.562rem;
          text-align: left;
        }
      }

      > .label {
        font-size: .75rem;
        line-height: 1.125rem;
        color: #5E686C;
        max-width: 25.562rem;
      }

      .list {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        > a {
          img {
            width: 4.562rem;
            height: 3.312rem;
            object-fit: cover;
            object-position: center;
            border-radius: .5rem;
            margin-right: .5rem;

            &:hover {
              filter: brightness(.5);
            }
          }
        }
      }
    }
  }
</style>
