<template>
  <div class="white-container">
    <h2>Оценка проекта</h2>

    <review-question v-for="(reviewQuestion, index) in reviewAnswers"
                     :question="reviewQuestion"
                     :index="index"
                     :key="`reviewQuestion${index}`"
                     :is-editing="getProjectReview && !getProjectReview.status || !getProjectReview"
                     @submitReviewQuestion="submitReviewQuestion($event, false)"/>

    <div class="buttons__wrapper" v-if="getProjectReview && !getProjectReview.status || !getProjectReview">
      <button class="btn-white" @click="saveReview(false)" :disabled="buttonDisable">
        Отправить оценку
      </button>
    </div>

    <div v-if="isProjectFinal" class="final">
      <h2>Оценка проекта финал</h2>

      <review-question v-for="(reviewQuestion, index) in reviewAnswersFinal"
                       :question="reviewQuestion"
                       :index="index"
                       :key="`reviewQuestionFinal${index}`"
                       :is-editing="getProjectReviewFinal && !getProjectReviewFinal.status || !getProjectReviewFinal"
                       @submitReviewQuestion="submitReviewQuestion($event, true)"/>

      <div class="buttons__wrapper" v-if="getProjectReviewFinal && !getProjectReviewFinal.status || !getProjectReviewFinal">
        <button class="btn-white" @click="saveReview(true)" :disabled="buttonDisable">
          Отправить оценку
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ReviewQuestion from './ReviewQuestion'

export default {
  name: 'ProjectMapReviewBlock',

  props: {
    projectAppeal: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      buttonDisable: false,
      reviewAnswers: [],
      reviewAnswersFinal: []
    }
  },

  watch: {
    getProjectQestion () {
      this.reviewAnswers = this.getProjectQestion.map((question) => {
        return {
          ...question,
          answer: null
        }
      })
      this.reviewAnswersFinal = this.reviewAnswers
      this.$store.dispatch('getProjectReview', this.$route.params.id)
      this.$store.dispatch('getProjectReviewFinal', this.$route.params.id)
    },

    getProjectReview () {
      this.reviewAnswers = this.reviewAnswers.map((question) => {
        const answerIndex = this.getProjectReview.answers.findIndex(answer => answer.id === question.id)

        return {
          ...question,
          answer: answerIndex !== -1 ? this.getProjectReview.answers[answerIndex].answer : null
        }
      })
    },

    getProjectReviewFinal () {
      this.reviewAnswersFinal = this.reviewAnswersFinal.map((question) => {
        const answerIndex = this.getProjectReviewFinal.answers.findIndex(answer => answer.id === question.id)

        return {
          ...question,
          answer: answerIndex !== -1 ? this.getProjectReviewFinal.answers[answerIndex].answer : null
        }
      })
    }
  },

  mounted () {
    this.$store.dispatch('setProjectQestion')
  },

  methods: {
    submitReviewQuestion (answer, isFinal) {
      if (isFinal) {
        this.reviewAnswersFinal[answer.review].answer = answer.answer
      } else {
        this.reviewAnswers[answer.review].answer = answer.answer
      }
    },

    saveReview (isFinal) {
      this.buttonDisable = true
      const formData = new FormData()
      formData.append('is_final', isFinal)
      formData.append('status', 1)

      if (isFinal) {
        formData.append('answers', JSON.stringify(this.reviewAnswersFinal))

        if (this.getProjectReviewFinal) {
          formData.append('_method', 'PUT')

          this.$store.dispatch('updateProjectReview', {
            id: this.getProjectReviewFinal.id,
            data: formData
          })
        } else {
          formData.append('project_appeal_id', this.projectAppeal.id)

          this.$store.dispatch('storeProjectReview', formData)
        }
      } else {
        formData.append('answers', JSON.stringify(this.reviewAnswers))

        if (this.getProjectReview) {
          formData.append('_method', 'PUT')

          this.$store.dispatch('updateProjectReview', {
            id: this.getProjectReview.id,
            data: formData
          })
        } else {
          formData.append('project_appeal_id', this.projectAppeal.id)

          this.$store.dispatch('storeProjectReview', formData)
        }
      }
    },

    submitReview (isFinal) {
      this.buttonDisable = true
      this.saveReview(isFinal)
      this.$store.dispatch('getProjectReviewFinal', this.$route.params.id)
      setTimeout(() => {
        if (isFinal) {
          if (this.reviewAnswersFinal.some(review => !review.answer)) {
            this.$store.commit('setMessage', {
              type: 'error',
              text: 'Необходимо ответить на все вопросы'
            })
          } else {
            const formData = new FormData()
            formData.append('answers', JSON.stringify(this.reviewAnswersFinal))
            formData.append('is_final', isFinal)
            formData.append('_method', 'PUT')
            formData.append('status', 1)

            this.$store.dispatch('updateProjectReview', {
              id: this.getProjectReviewFinal.id,
              data: formData
            })
          }
        } else {
          if (this.reviewAnswers.some(review => !review.answer)) {
            this.$store.commit('setMessage', {
              type: 'error',
              text: 'Необходимо ответить на все вопросы'
            })
          } else {
            const formData = new FormData()
            formData.append('answers', JSON.stringify(this.reviewAnswers))
            formData.append('is_final', isFinal)
            formData.append('_method', 'PUT')
            formData.append('status', 1)

            this.$store.dispatch('updateProjectReview', {
              id: this.getProjectReview.id,
              data: formData
            })
          }
        }
      }, 5000)
    }
  },

  computed: {
    isProjectFinal () {
      return this.projectAppeal.status_code === 'FINAL'
    },

    getProjectQestion () {
      return this.$store.getters.getProjectQestion
    },

    getProjectReview () {
      return this.$store.getters.getProjectReview
    },

    getProjectReviewFinal () {
      return this.$store.getters.getProjectReviewFinal
    }
  },

  components: {
    ReviewQuestion
  }
}
</script>

<style scoped lang="scss">
.btn-white:disabled {
  opacity: .3;
}

  .white-container {
    width: 27.25rem;
    margin: 0 -3.812rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.875rem !important;

    h2 {
      margin-bottom: 1.25rem;
    }

    .buttons__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > .btn-white {
        width: 11.5rem;
      }
    }

    .final {
      margin-top: 2rem;
    }
  }
  @media screen and (max-width: 768px){
    .white-container {
      width: 100%;
      margin-top: 2rem;
    }
  }
</style>
