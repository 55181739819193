<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>

    <div class="container">
      <h2>
        Карта проекта
      </h2>

      <div class="containers__wrapper">
        <project-map-data/>

        <project-map-review-block v-if="getProjectUserRole === 'EXPERT' && getIndividualProject" :project-appeal="getIndividualProject"/>

        <project-map-experts-block v-if="getProjectUserRole === 'ORGANIZER' && getIndividualProject" :project-appeal="getIndividualProject"/>
      </div>
    </div>
  </div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb'
import ProjectMapData from '@/components/projectMap/ProjectMapData'
import ProjectMapReviewBlock from '@/components/projectMap/ProjectMapReviewBlock'
import ProjectMapExpertsBlock from '@/components/projectMap/ProjectMapExpertsBlock'

export default {
  name: 'ProjectMap',

  data () {
    return {
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Карта проекта'
        }
      ]
    }
  },

  created () {
    this.$store.dispatch('setIndividualProject', this.$route.params.id)
  },

  computed: {
    getIndividualProject () {
      return this.$store.getters.getIndividualProject
    },

    getProjectUserRole () {
      return this.$store.getters.getProjectUserRole
    }
  },

  components: {
    ProjectMapExpertsBlock,
    ProjectMapReviewBlock,
    ProjectMapData,
    BreadCrumb
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  ::v-deep h2 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 1.75rem;
    color: $mainFontColor;
    margin-bottom: 2rem;
  }

  .containers__wrapper {
    display: flex;
    justify-content: space-between;

    > ::v-deep .white-container {
      padding: 2.5rem 3.125rem;
    }
  }
  @media screen and (max-width: 768px){
    .containers__wrapper{
      flex-direction: column;
      width: 100%;
    }
  }
</style>
