<template>
  <div class="white-container">
    <button class="submit btn-blue" @click="deleteProjectAppeal">
      Удалить
    </button>

    <h2>Макс. количество экспертов</h2>
    <input type="number" class="input blue" v-model="maxExpertCount">
    <button class="submit btn-blue" @click="changeMaxExpertCount">
      Сохранить
    </button>

    <h2>Эксперты проекта</h2>
    <custom-select key="expertsSelect"
                   class="select expert__select"
                   :list="getExperts"
                   @InputsContent="addExpert($event, false)"
                   :placeholder="`${ $store.getters.GET_LANG ? 'Добавить эксперта' : 'Add expert' }`"/>

    <article class="expert__block" v-for="(expert, index) in getIndividualProjectExperts" :key="`expert${index}`">
      <p>
        {{ expert.full_name }}
      </p>
      <button @click="removeExpert(expert)">
        x
      </button>
    </article>

    <h2>
      Оценки экспертов
      <button @click="downloadExcel" class="btn-blue">
        Выгрузка Excel
      </button>
    </h2>
    <article class="review__block" v-for="(review, index) in getIndividualProjectExpertReviews" :key="`review${index}`">
      <p>ФИО:<b> {{ review.full_name }}</b></p>
      <div class="answers">
        <p v-for="(answer, index) in review.answers" :key="`answer${index}`">
          <span>{{ answer.title }}</span>
          <span>{{ answer.answer }}</span>
        </p>
      </div>
    </article>

    <hr v-if="isProjectFinal">

    <div v-if="isProjectFinal">
      <h2>
        Эксперты проекта финал
      </h2>
      <custom-select
        key="expertsSelectFinal"
        class="select expert__select"
        :list="getExperts"
        @InputsContent="addExpert($event, true)"
        :placeholder="`${ $store.getters.GET_LANG ? 'Добавить эксперта' : 'Add expert' }`"/>

      <article class="expert__block" v-for="(expert, index) in getIndividualProjectExpertsFinal" :key="`expertFinal${index}`">
        <p>
          {{ expert.full_name }}
        </p>
        <button @click="removeExpert(expert)">
          x
        </button>
      </article>

      <h2>
        Оценки экспертов
        <button @click="downloadExcel" class="btn-blue">
          Выгрузка Excel
        </button>
      </h2>
      <article class="review__block" v-for="(review, index) in getIndividualProjectExpertReviewsFinal" :key="`reviewFinal${index}`">
        <p>ФИО:<b> {{ review.full_name }}</b></p>
        <div class="answers">
          <p v-for="(answer, index) in review.answers" :key="`answer${index}`">
            <span>{{ answer.title }}</span>
            <span>{{ answer.answer }}</span>
          </p>
        </div>
      </article>

    </div>
  </div>
</template>

<script>

import CustomSelect from '@/components/forms/CustomSelect'

export default {
  name: 'ProjectMapExpertsBlock',

  props: {
    projectAppeal: {
      type: Object,
      required: false,
      default: () => {}
    }
  },

  data () {
    return {
      reviewAnswers: [],
      maxExpertCount: 3
    }
  },

  watch: {
    getProjectQestion () {
      this.reviewAnswers = this.getProjectQestion.map((question) => {
        return {
          ...question,
          answer: null
        }
      })
      this.$store.dispatch('getProjectReview', this.$route.params.id)
    },

    getProjectReview () {
      this.reviewAnswers = this.reviewAnswers.map((question) => {
        const answerIndex = this.getProjectReview.answers.findIndex(answer => answer.id === question.id)
        console.log(answerIndex)

        return {
          ...question,
          answer: answerIndex !== -1 ? this.getProjectReview.answers[answerIndex].answer : null
        }
      })
    },

    projectAppeal: {
      immediate: true,
      handler () {
        this.maxExpertCount = this.projectAppeal.max_expert_count
      }
    }
  },

  mounted () {
    this.$store.dispatch('getExperts')
  },

  methods: {
    deleteProjectAppeal () {
      this.$store.dispatch('deleteProjectAppeal', this.projectAppeal.id)
    },

    addExpert (expert, isFinal) {
      const formData = new FormData()
      formData.append('expert_id', expert.id)
      formData.append('project_appeal_id', this.projectAppeal.id)
      formData.append('is_final', isFinal)
      this.$store.dispatch('addProjectAppealExpert', formData)
    },

    removeExpert (expert) {
      this.$store.dispatch('removeProjectAppealExpert', {
        expert_id: expert.id,
        project_appeal_id: this.projectAppeal.id
      })
    },

    changeMaxExpertCount () {
      const formData = new FormData()
      formData.append('max_expert_count', this.maxExpertCount)
      this.$store.dispatch('changeMaxExpertCount', {
        id: this.projectAppeal.id,
        data: formData
      })
    },

    async downloadExcel () {
      await fetch(`${this.$store.getters.getUrlApi}api/user/project_appeals/${this.projectAppeal.id}/reviews/excel`, {
        method: 'GET',
        headers: {
          Authorization: localStorage.getItem('token')
        }
      })
        .then(res => res.blob())
        .then(blob => {
          const file = window.URL.createObjectURL(blob)
          window.location.assign(file)
        })
        .catch(error => console.log('error', error))
    }
  },

  computed: {
    getIndividualProjectExpertReviews () {
      return this.$store.getters.getIndividualProjectExpertReviews.map((review) => {
        const element = this.getExperts.find(expert => expert.id === review.user_id)

        return {
          ...review,
          full_name: element && element.full_name ? element.full_name : null
        }
      })
    },

    getIndividualProjectExpertReviewsFinal () {
      return this.$store.getters.getIndividualProjectExpertReviewsFinal.map((review) => {
        const element = this.getExperts.find(expert => expert.id === review.user_id)

        return {
          ...review,
          full_name: element && element.full_name ? element.full_name : null
        }
      })
    },

    getIndividualProjectExperts () {
      return this.$store.getters.getIndividualProjectExperts.map((expert) => {
        const fullExpert = this.getExperts.find(fullExpert => fullExpert.id === expert.user_id)

        return {
          ...expert,
          full_name: fullExpert && fullExpert.full_name ? fullExpert.full_name : null
        }
      })
    },

    getIndividualProjectExpertsFinal () {
      return this.$store.getters.getIndividualProjectExpertsFinal.map((expert) => {
        const fullExpert = this.getExperts.find(fullExpert => fullExpert.id === expert.user_id)

        return {
          ...expert,
          full_name: fullExpert && fullExpert.full_name ? fullExpert.full_name : null
        }
      })
    },

    getExperts () {
      return this.$store.getters.getExperts.map((expert) => {
        return {
          ...expert,
          title: expert.full_name
        }
      })
    },

    getProjectQestion () {
      return this.$store.getters.getProjectQestion
    },

    getProjectReview () {
      return this.$store.getters.getProjectReview
    },

    isProjectFinal () {
      return this.projectAppeal.status_code === 'FINAL'
    }
  },

  components: {
    CustomSelect
  }
}
</script>

<style scoped lang="scss">
  .white-container {
    width: 27.25rem;
    margin: 0 -3.812rem auto 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.875rem !important;

    h2 {
      margin-bottom: 1.25rem;

      > a {
        font-size: 1rem;
        color: #246CB7;
      }

      &:last-of-type {
        margin-top: 3rem;
      }
    }

    hr {
      width: 100%;
      border: 1px solid #eee;
      margin: 3rem 0;
    }

    .submit {
      margin: 1rem 0 2rem;
    }

    .expert__select {
      margin-bottom: 2rem;
    }

    .expert__block, .review__block {
      width: 100%;
      margin-bottom: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 1rem;
      }

      button {
        width: 1rem;
        height: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
      }
    }

    .review__block {
      flex-direction: column;
      align-items: flex-start;
      border-bottom: none;
      margin-bottom: 1rem;

      .answers {
        display: flex;
        flex-direction: column;
        width: 100%;

        p {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          border-bottom: 1px solid #eee;
        }
      }
    }

    .buttons__wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;

      > .btn-white {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 768px){
    .white-container {
      width: 100%;
      margin-top: 2rem;
    }
  }
</style>
