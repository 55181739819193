<template>
  <section>
    <data-field v-for="(dataField,index) in dataFields" :data-field="dataField" :key="`dataField${index}`"/>

    <div class="buttons__container">
      <button class="btn-white" @click="changeCurrentTab(0)">
        Отмена
      </button>

      <button class="btn-blue" @click="changeCurrentTab(2)">
        Далее
      </button>
    </div>
  </section>
</template>

<script>
import DataField from './DataField'

export default {
  name: 'SecondStep',
  data () {
    return {
      dataFields: []
    }
  },
  mounted () {
    if (this.$store.getters.getIndividualProject.lenth !== 0) {
      this.dataArr()
    }
  },
  watch: {
    '$store.getters.getIndividualProject' () {
      this.dataArr()
    }
  },
  methods: {
    changeCurrentTab (index) {
      this.$emit('changeCurrentTab', index)
    },
    dataArr () {
      const data = this.$store.getters.getIndividualProject.organization
      this.dataFields = [
        {
          label: 'Ваша организация',
          content: data.name
        },
        {
          label: 'Сайт организации',
          content: data.site
        },
        {
          label: 'E-mail организации',
          content: data.email
        },
        {
          label: 'Ваша должность',
          content: data.position
        },
        {
          label: 'Сколько сотрудников работает сейчас в организации на постоянной основе?',
          content: data.employees_count
        },
        {
          label: 'Ваши функциональные обязанности',
          content: data.responsibilities
        }
      ]
    }
  },
  components: {
    DataField
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/scss/utils/vars";

  section {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;

    > * {
      margin-bottom: 1.5rem;
    }

    > .buttons__container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 1.375rem;
      margin-top: 2.5rem;
      border-top: .06rem solid $neutral-secondary;
      margin-bottom: 0;

      > .btn-white, > .btn-blue {
        width: 10rem;
        text-align: center;
      }
    }
  }
</style>
